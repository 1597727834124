(function () {
  'use strict';

  let angular = window.angular;

  DataSourceRouter.$inject = ['$stateProvider'];

  angular.module('app').config(DataSourceRouter);

  function DataSourceRouter($stateProvider) {
    $stateProvider
      .state('member.customer.show.dataSource', {
        url: '/data-source',
        redirectTo: 'member.customer.show.dataSource.apiKey',
        template: '<ui-view></ui-view>',
        authenticated: true,
        ncyBreadcrumb: {
          label: 'Data Source',
        },
        resolve: {
          hasTool: [
            'customer',
            'AppUtils',
            function (customer, utils) {
              return true;
            },
          ],
        },
      })
      .state('member.customer.show.dataSource.apiKey', {
        url: '/api-key/:apiKeyId',
        component: 'connectionApiKey',
        authenticated: true,
        params: {
          apiKeyId: {
            type: 'string',
            dynamic: true,
            squash: true,
          },
        },
        ncyBreadcrumb: {
          label: '{{"entities.apikey.modelNamePl" | translate}}',
        },
        resolve: {
          apiKeys: [
            'Customer',
            '$transition$',
            'AppUtils',
            function (Customer, $transition$, utils) {
              let params = $transition$.params();

              return Customer.apiKeys({ id: params.customerId })
                .$promise.then(function (keys) {
                  keys.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));
                  let tree = [];
                  keys.forEach((key) => {
                    tree.push({
                      id: key.id,
                      text: key.name,
                      parent: '#',
                    });
                  });
                  return { tree: tree, keys: keys };
                })
                .catch((err) => {
                  throw utils.getHTTPError(err, {
                    redirectTo: 'member.customer.show.home',
                    params: { customerId: params.customerId },
                  });
                });
            },
          ],
        },
      })
      .state('member.customer.show.dataSource.device', {
        url: '/devices/:deviceId',
        component: 'connectionDevice',
        authenticated: true,
        params: {
          deviceId: {
            type: 'string',
            dynamic: true,
            squash: true,
          },
        },
        ncyBreadcrumb: {
          label: '{{"entities.device.modelNamePl" | translate}}',
        },
        resolve: {
          dependencies: [
            '$ocLazyLoad',
            '$rootScope',
            function ($ocLazyLoad, $root) {
              let style = $root.darkMode ? 'dark' : 'light';
              return $ocLazyLoad.load([
                window.assetsPath + '/js/vendors/editors/jsoneditor.min.js',
                window.assetsPath + '/js/vendors/editors/jsondiffpatch.umd.js',
                window.assetsPath + '/js/vendors/editors/simplemde.min.js',
                window.assetsPath + `/css/editors-${style}.css`,
              ]);
            },
          ],
          assets: [
            'assetUtilsService',
            function (AssetUtils) {
              return AssetUtils.loadTree(true, ['id', 'name', 'assetId', 'projectId'], undefined);
            },
          ],
          devices: [
            'Customer',
            '$transition$',
            'AppUtils',
            'assetUtilsService',
            function (Customer, $transition$, utils, assetUtilsService) {
              let params = $transition$.params();
              let devices = [];
              let deviceMap = {};
              return Customer.devices({ id: params.customerId })
                .$promise.then((result) => {
                  devices = result;
                  devices.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));
                  devices.forEach((device) => {
                    device.status = assetUtilsService.getStatus(device);
                    deviceMap[device.id] = device;
                  });

                  let tree = [];
                  devices.forEach((device) => {
                    tree.push({
                      id: device.id,
                      text: device.name,
                      version: device.version,
                      parent: '#',
                      li_attr: {
                        'data-status': device.status === 'offline' ? 'offline' : 'undefined',
                      },
                    });
                  });
                  return { tree: tree, devices: devices };
                })
                .catch((err) => {
                  throw utils.getHTTPError(err, {
                    redirectTo: 'member.customer.show.home',
                    params: { customerId: params.customerId },
                  });
                });
            },
          ],
        },
      })
      .state('member.customer.show.dataSource.telegramChat', {
        url: '/telegramChats/:telegramChatId',
        component: 'connectionTelegramChat',
        authenticated: true,
        params: {
          telegramChatId: {
            type: 'string',
            dynamic: true,
            squash: true,
          },
        },
        ncyBreadcrumb: {
          label: '{{"entities.telegramchat.modelNamePl" | translate}}',
        },
        resolve: {
          dependencies: [
            '$ocLazyLoad',
            '$rootScope',
            function ($ocLazyLoad, $root) {
              const style = $root.darkMode ? 'dark' : 'light';
              return $ocLazyLoad.load([
                window.assetsPath + '/js/vendors/editors/simplemde.min.js',
                window.assetsPath + `/css/editors-${style}.css`,
              ]);
            },
          ],
          telegramChats: [
            'Customer',
            '$transition$',
            'AppUtils',
            'assetUtilsService',
            function (Customer, $transition$, utils, assetUtilsService) {
              let params = $transition$.params();

              return Customer.telegramChats({ id: params.customerId })
                .$promise.then(function (telegramChats) {
                  telegramChats.sort((a, b) =>
                    a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
                  );
                  let tree = [];
                  telegramChats.forEach((telegramChat) => {
                    tree.push({
                      id: telegramChat.id,
                      text: telegramChat.name,
                      parent: '#',
                    });
                  });
                  return { tree: tree, telegramChats: telegramChats };
                })
                .catch((err) => {
                  throw utils.getHTTPError(err, {
                    redirectTo: 'member.customer.show.home',
                    params: { customerId: params.customerId },
                  });
                });
            },
          ],
        },
      })
      .state('member.customer.show.dataSource.emergencyContact', {
        url: '/emergencyContacts/:emergencyContactId',
        component: 'connectionEmergencyContact',
        authenticated: true,
        params: {
          emergencyContactId: {
            type: 'string',
            dynamic: true,
            squash: true,
          },
        },
        ncyBreadcrumb: {
          label: '{{"entities.emergencycontact.modelNamePl" | translate}}',
        },
        resolve: {
          dependencies: [
            '$ocLazyLoad',
            '$rootScope',
            function ($ocLazyLoad, $root) {
              const style = $root.darkMode ? 'dark' : 'light';
              return $ocLazyLoad.load([
                'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.15/css/intlTelInput.css',
                'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.15/js/intlTelInput-jquery.min.js',
                'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.15/js/utils.min.js',
                'https://cdn.jsdelivr.net/npm/ng-intl-tel-input@2.0.0/dist/ng-intl-tel-input.min.js',
                window.assetsPath + '/js/vendors/editors/simplemde.min.js',
                window.assetsPath + `/css/editors-${style}.css`,
              ]);
            },
          ],
          emergencyContacts: [
            'Customer',
            '$transition$',
            'AppUtils',
            function (Customer, $transition$, utils) {
              let params = $transition$.params();

              return Customer.emergencyContacts({ id: params.customerId })
                .$promise.then(function (emergencyContacts) {
                  emergencyContacts.sort((a, b) =>
                    a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
                  );
                  let tree = [];
                  emergencyContacts.forEach((current) => {
                    tree.push({
                      id: current.id,
                      text: current.name,
                      parent: '#',
                    });
                  });
                  return { tree: tree, emergencyContacts: emergencyContacts };
                })
                .catch((err) => {
                  throw utils.getHTTPError(err, {
                    redirectTo: 'member.customer.show.home',
                    params: { customerId: params.customerId },
                  });
                });
            },
          ],
        },
      });
  }
})();

(function () {
  'use strict';
  const angular = window.angular;

  angular.module('app').config(AccountRouter);

  AccountRouter.$inject = ['$stateProvider'];

  function AccountRouter($stateProvider) {
    $stateProvider
      .state('member.account', {
        url: '/account',
        abstract: true,
        authenticated: true,
        template:'<div class="container marginT20"><ul class="clear-tabs"><li ui-sref-active="active"><a ui-sref="member.account.profile"><span translate>entities._all.profile</span></a></li><li ui-sref-active="active"><a ui-sref="member.account.organization"><span translate>entities.organization.modelName</span></a></li></ul><div ui-view></div></div>',
        redirectTo: 'member.account.profile',
        ncyBreadcrumb: {
          skip: true,
        },
        resolve: {
          countries: [
            'StaticService',
            function (StaticService) {
              return StaticService.Country.find();
            },
          ],
          timeZones: [
            'StaticService',
            function (StaticService) {
              return StaticService.TimeZone.find();
            },
          ],
        },
      })
      .state('member.account.profile', {
        url: '/profile',
        component: 'accountProfile',
        authenticated: true,
        ncyBreadcrumb: {
          label: '{{"entities._all.profile" | translate}}',
        },
        resolve: {
          dependencies: [
            '$ocLazyLoad',
            function ($ocLazyLoad) {
              return $ocLazyLoad.load([
                'https://cdn.jsdelivr.net/gh/davidshimjs/qrcodejs/qrcode.min.js',
              ]);
            },
          ],
        },
      })
      .state('member.account.organization', {
        url: '/organization',
        component: 'accountOrganization',
        requireADLogin: true,
        authenticated: true,
        ncyBreadcrumb: {
          label: '{{"entities.organization.modelName" | translate}}',
        },
        resolve: {
          organization: [
            'OrganizationService',
            function (OrganizationService) {
              return OrganizationService.getCurrent({
                include: [
                  'container',
                  {
                    relation: 'admins',
                    scope: { include: 'container' },
                  },
                ],
              });
            },
          ],
          users: [
            'OrganizationService',
            'organization',
            function (OrganizationService, organization) {
              return OrganizationService.getUsers(organization);
            },
          ],
        },
      });
  }
})();
